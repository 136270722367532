.checkbox-wrapper {
  display: flex;
  width: 100%;
  justify-content: center;
}

.checkbox-wrapper input[type='checkbox'] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 20px;
  height: 20px;
  border-radius: 4px;
  margin-right: 8px;
  border: 1px solid #aec9ea;
  outline: none;
  cursor: pointer;
}

.checkbox-wrapper label {
  display: flex;
  align-items: center;
  width: fit-content;
}

input {
  background-color: #fff;
}

input.checked {
  background-color: #196abb;
  position: relative;
}

input.checked::before {
  content: '';
  font-size: 1.5em;
  color: #fff;
  position: absolute;
  right: 1px;
  top: -5px;
}

.checkbox-wrapper input[type='checkbox']:disabled {
  border-color: #c0c0c0;
  background-color: #c0c0c0;
}

.checkbox-wrapper input[type='checkbox']:disabled + span {
  color: #c0c0c0;
}

.link {
  color: #000000;
  text-decoration: underline;
}

.span {
  color: #000000;
  font-family: Circe;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}
