@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./circe.ttf');
}

@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('./circe-bold.ttf');
}

@font-face {
  font-family: 'Circe';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('./circe-light.ttf');
}
