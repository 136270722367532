.footer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 120px;
  margin-top: 20px;
  align-self: flex-end;
  margin-bottom: 28px;
}

.container {
  display: flex;
  gap: 4px;
  align-items: center;
  font-family: Circe;
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: white;
  max-width: fit-content;
}

@media screen and (max-width: 750px) {
  .footer {
    padding: 0 12px;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 12px 40px;
    grid-auto-flow: row;
    grid-template-areas:
      '. .'
      '. .';
    justify-content: start;
  }
}
