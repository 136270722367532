.message {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: left;
  background: #ffffff;
  border-radius: 20px;
  padding: 20px;
  width: fit-content;
  max-width: 90%;
}

.message p {
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  text-align: left;
}

.message h3 {
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  text-align: left;
}

.message.user {
  align-self: flex-end;
}

.message.user h3 {
  background: linear-gradient(132.21deg, #784bff 46.24%, #66b3ff 86.3%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.message.responce {
  align-self: flex-start;
}

.message.responce h3 {
  color: #aec9ea;
}
.loader {
  margin-left: 12px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d30b0b;
  box-shadow: 12px 0 #ac0808, -12px 0 #1b0000;
  position: relative;
  animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
  0% {
    background-color: #aec9ea;
    box-shadow: 12px 0 #aec9ea, -12px 0 #66b3ff;
  }
  50% {
    background-color: #66b3ff;
    box-shadow: 12px 0 #aec9ea, -12px 0 #aec9ea;
  }
  100% {
    background-color: #aec9ea;
    box-shadow: 12px 0 #66b3ff, -12px 0 #aec9ea;
  }
}

@media screen and (max-height: 900px) {
  .message p {
    font-size: 14px;
    line-height: 20px;
  }

  .message {
    padding: 16px;
    gap: 4px;
  }
}

@media screen and (max-width: 400px) {
  .messages {
    padding-right: 4px;
  }
  .message p {
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
  }

  .message {
    gap: 4px;
  }
  .message h3 {
    font-size: 12px;
    line-height: 16px;
  }
}
