.chat {
  z-index: 3;
  display: flex;
  justify-content: center;
  gap: 32px;
  padding-left: 10px;
  width: 100%;
  max-width: 90vw;
}

.container {
  width: 100%;
}

.messages {
  overflow-y: scroll;
  max-height: 30vh;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-right: 8px;
}

.inputContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}

.label {
  width: 100%;
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 12px;
  background-color: #fff;
  margin-top: 8px;
  border-radius: 20px;
  /* height: 64px; */
}

.input {
  background-color: transparent;
  width: 100%;
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #000;
  border: none;
  outline: none;
}

.button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.button img {
  width: 100%;
  height: 100%;
}

.tabletContainer {
  display: flex;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: #aec9ea;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #8eb6e7;
}

@media screen and (max-height: 900px) {
  .button {
    width: 30px;
    height: 30px;
  }

  .input {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
  }
}

/* @media screen and (max-width: 900px) {
  .chat {
    max-width: 784px;
  }
} */

@media screen and (max-width: 400px) {
  /* .chat {
    max-width: 352px;
  } */

  .label {
    width: 100%;
    display: flex;
    gap: 20px;
    align-items: center;
    padding: 8px 12px;
    background-color: #fff;
    margin-top: 8px;
    border-radius: 20px;
  }

  .input {
    font-size: 14px;
    line-height: 20px;
  }
  .button img {
    width: 32px;
    height: 32px;
  }
}
