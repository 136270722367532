.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  gap: 20px;
}

.title {
  font-family: Circe;
  font-size: 48px;
  font-weight: 400;
  line-height: 52px;
  text-align: center;
  color: #ffffff;
}

.subtitle {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
}
.closeButton:hover {
  cursor: pointer;
  background-image: url(../../images/close-button-hover.svg);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputs {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 16px;
  grid-row-gap: 12px;
  grid-auto-flow: row;
  grid-template-areas:
    'name name . .'
    '. . country country';
}

.name {
  grid-area: name;
}

.country {
  grid-area: country;
}

.label {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: left;
  font-family: Circe;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #aec9ea;
}

.input {
  width: 100%;
  padding: 20px;
  background-color: #f1f5fb;
  border: 1px solid #aec9ea;
  border-radius: 20px;
  font-family: Circe;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}

.input:focus-visible {
  outline: none;
}

.input.error {
  background-color: #ffe2e2;
  border: 1px solid #efabaf;
}

.button {
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #66b3ff;
  border-radius: 20px;
  color: #66b3ff;
  font-family: Circe;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: #784bff;
}

.button:hover {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(99.24deg, #66b3ff 1.74%, #784bff 108.3%);
}
.button:active {
  cursor: pointer;
  color: #fff;
  background: linear-gradient(100.52deg, #49a3fc -2.64%, #882ae6 120.84%);
}
.button:disabled {
  color: #aec9ea;
  border: 1px solid #aec9ea;
  background-color: #fff;
}
.button:disabled:hover {
  background: #fff;
  opacity: 0.9;
}
@media screen and (max-width: 870px) {
  .inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 12px;
    grid-template-areas:
      'name name'
      '. .'
      '. .'
      'country country';
  }

  .title {
    font-size: 40px;
    line-height: 44px;
  }

  .subtitle {
    font-size: 20px;
    line-height: 24px;
  }
}

@media screen and (max-width: 400px) {
  .inputs {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
    grid-row-gap: 8px;
    grid-template-areas:
      'name'
      '.'
      '.'
      '.'
      '.'
      'country';
  }

  .title {
    font-size: 28px;
    line-height: 28px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
  }
}
