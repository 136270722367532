.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.avatarContainer {
  background-color: transparent;
  width: 100%;
  max-width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  /* margin-bottom: -100px; */
  position: relative;
  height: 100%;
  /* -webkit-box-shadow: 0px -100px 8px -5px rgba(255, 255, 255, 0.23) inset;
  -moz-box-shadow: 0px -100px 8px -5px rgba(255, 255, 255, 0.23) inset;
  box-shadow: 0px -100px 8px -5px rgba(255, 255, 255, 0.23) inset; */
}

.avatarContainer::before {
  content: '';
  display: flex;
  width: 100%;
  height: 100px;
  position: absolute;
  bottom: 0;

  background-color: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0px -50px 8px -6px transparent inset;
  -moz-box-shadow: 0px -50px 8px -6px transparent inset;
  box-shadow: 0px -50px 8px -6px transparent inset;
  filter: blur(100px);
}

.avatar {
  height: 35vh;
  width: 35vh;
  border-radius: 50%;
  /* background-color: gray; */
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatarImage {
  position: absolute;
  width: 100%;
  z-index: 0;
}

.audio-recorder {
  border: 1px solid red;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 0px;
}

/* @media screen and (max-height: 1000px) {
  .avatar {
    width: 300px;
    height: 300px;
  }
} */
