.overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  padding: 20px;
  padding-top: 40px;
  background-color: #ffffff;
  border-radius: 15px;
  position: relative;
  max-width: 400px;
  margin: 20px;
}

.button {
  height: 20px;
  width: 20px;
  background-image: url('../../images/close-button.svg');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: none;
  background-color: transparent;
  position: absolute;
  top: 20px;
  right: 20px;
}

.button:hover {
  cursor: pointer;
  background-image: url('../../images/close-button-hover.svg');
}

.text {
  font-family: Circe;
  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  color: #784bff;
}
