.container {
  display: flex;
  align-items: flex-end;
}

.button {
  width: 100px;
  height: 76px;
  border: none;
  outline: none;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(../../images/micro-default.png);
  background-position: center;
  background-color: transparent;
}

.button.active {
  background-image: url(../../images/micro-hover.png);
}

.button:disabled {
  cursor: pointer;
  background-image: url(../../images/micro-disabled.png);
}

@media screen and (max-height: 900px) {
  .button {
    /* width: 80px; */
    height: 54px;
  }
}

@media screen and (max-width: 400px) {
  .button {
    width: 54px;
    height: 50px;
  }
}
