.app {
  background: linear-gradient(90deg, #66b3ff, #784bff);
  display: flex;
  flex-direction: column;
  /* height: 100vh; */
  position: relative;
  overflow: hidden;
}

.logo {
  position: absolute;
  top: 24px;
  left: 28px;
}

.bg {
  position: absolute;
  /* width: 80%; */
  height: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 1100px) {
  .bg {
    /* height: auto; */
    width: 130%;
  }
}

@media screen and (max-width: 400px) {
  .bg {
    top: 0%;
    left: 0%;

    transform: rotate(30deg);
  }

  .logo {
    width: 52px;
    height: 35px;
  }
}
